<template>
  <div class="content">
    <div class="export">
      <div class="export__block">
        <div class="export__title">Экспорт в Excel</div>
        <div class="export__list">
          <button
            v-for="(file, index) in files" :key="index"
            type="button"
            @click="onGetFile(file)"
            class="export__item"
            :class="{ 'export__item--loading': file.loader }"
          >
            <img v-show="file.extension === 'xlsx'" src="@/assets/img/xls-icon.svg" alt="Extension" class="export__icon">
            <img v-show="file.extension === 'zip'" src="@/assets/img/zip-icon.svg" alt="Extension" class="export__icon">
            <div class="export__name">{{ file.name }}</div>
            <img class="export__loader" src="@/assets/img/preloader-theme.svg" alt="">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import {
  getParticipantsExport,
  getNumbersExport,
  getTransfersExport,
  getManagementsExport,
  getSmiExport
} from '@/http'

export default {
  name: 'Export',
  data() {
    return {
      files: [
        {
          name: 'Участники',
          name_file: 'Участники',
          loader: false,
          action: getParticipantsExport,
          extension: 'xlsx',
        },
        {
          name: 'Номера',
          name_file: 'Номера',
          loader: false,
          action: getNumbersExport,
          extension: 'xlsx',
        },
        {
          name: 'Трансфер',
          name_file: 'Трансфер',
          loader: false,
          action: getTransfersExport,
          extension: 'xlsx',
        },
        {
          name: 'Дирекция и организаторы',
          name_file: 'Дирекция и организаторы',
          loader: false,
          action: getManagementsExport,
          extension: 'xlsx',
        },
        {
          name: 'СМИ',
          name_file: 'СМИ',
          loader: false,
          action: getSmiExport,
          extension: 'xlsx',
        },
      ],
    }
  },
  methods: {
    onGetFile(file) {
      file.loader = true
      file.action()
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${file.name_file} ${format(new Date(), 'dd.MM.yyyy HH_mm')}.${file.extension}`)
          document.body.appendChild(link)
          link.click()
        })
        .finally(() => {
          file.loader = false
        })
    },
  }
}
</script>

<style scoped lang="sass">
.export
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: 30px

.export__block
  padding: 30px
  background: #f8fdff
  box-shadow: 0 0 4px rgba(151, 151, 151, 0.25)
  border-radius: 10px

.export__title
  margin-bottom: 20px
  font-size: 24px
  font-weight: 600

.export__item
  display: flex
  align-items: center
  gap: 15px
  margin-bottom: 20px
  cursor: pointer
  transition: opacity 0.3s

  &:active
    opacity: 0.8

  @media (hover: hover)
    &:hover,
    &:focus
      opacity: 0.8

  &:last-child
    margin-bottom: 0

.export__item--loading
  pointer-events: none

  .export__loader
    display: block

.export__loader
  display: none

.export__name
  color: $color-theme
  font-size: 16px
</style>
