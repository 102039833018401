<template>
  <main>
    <div class="welcome">
      <div class="welcome__content">
        <div class="welcome__part">
          <div class="welcome__center">
            <div class="welcome__title">СУМ фестиваля «На высоте»</div>
            <!--<a href="#" class="welcome__instruction">-->
              <!--<img src="@/assets/img/welcome/pdf-icon.svg" alt="">-->
              <!--<span class="welcome__instruction-text">Инструкция</span>-->
              <!--<img src="@/assets/img/welcome/instruction-download.svg" alt="">-->
            <!--</a>-->
            <div class="welcome__party">
              <div class="welcome__subt">Техническая поддержка</div>
              <div class="welcome__contacts">
                <a href="tel:+79280147272" class="welcome__contact">
                  <img src="@/assets/img/welcome/phone.svg" alt="tel-icon" />
                  <span class="welcome__contact-text">+7 (928) 014-72-72</span>
                </a>
                <a href="tg://resolve?domain=antonov_group" class="welcome__contact">
                  <img src="@/assets/img/welcome/tg.svg" alt="tg-icon" />
                  <span class="welcome__contact-text">antonov_group</span>
                </a>
                <a href="mailto:support@antonov.group" class="welcome__contact">
                  <img src="@/assets/img/welcome/email.svg" alt="mail-icon" />
                  <span class="welcome__contact-text">support@antonov.group</span>
                </a>
                <div class="welcome__contact">
                  <img src="@/assets/img/welcome/time.svg" alt="clock-icon" />
                  <span class="welcome__contact-text">ПН-ПТ 10:00 - 18:00 (Москва)</span>
                </div>
              </div>
            </div>
<!--            <div class="welcome__party">-->
<!--              <div class="welcome__subt">Адрес</div>-->
<!--              <div class="welcome__contacts">-->
<!--                <div class="welcome__contact">-->
<!--                  <img src="@/assets/img/welcome/mark-icon.svg" alt="" />-->
<!--                  <span class="welcome__contact-text">355017, г. Ставрополь, ул. Мира, 347, первый этаж</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="welcome__party">-->
<!--              <div class="welcome__subt">Время работы</div>-->
<!--              <div class="welcome__contacts">-->
<!--                <div class="welcome__contact">-->
<!--                  <img src="@/assets/img/welcome/time.svg" alt="" />-->
<!--                  <span class="welcome__contact-text">Пн-Пт: с 8:30 до 16:00</span>-->
<!--                </div>-->
<!--                <div class="welcome__contact">-->
<!--                  <img src="@/assets/img/welcome/time.svg" alt="" />-->
<!--                  <span class="welcome__contact-text">Cб: с 8:30 до 13:00</span>-->
<!--                </div>-->
<!--                <div class="welcome__contact">-->
<!--                  <img src="@/assets/img/welcome/time.svg" alt="" />-->
<!--                  <span class="welcome__contact-text">Вс: выходной</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <div class="welcome__bottom">
            <a href="https://antonov.group" target="_blank">
              <img src="@/assets/img/antonov-logo.svg" alt="Antonov Group" />
            </a>
          </div>
        </div>
        <div class="welcome__part">
          <div class="welcome__top">
            <div class="welcome__logo">
              <img :src="`${static_url}login-logo.svg`" alt="logo" />
            </div>
          </div>
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      static_url: process.env.VUE_APP_STATIC_URL,
    }
  }
}
</script>

<style lang="sass">
.welcome
  flex-grow: 1
  display: flex
  background-image: linear-gradient(180deg, #278BDB 0%, #3853AE 101.9%)

.welcome__content
  display: flex
  flex-grow: 1
  max-width: 1570px
  min-height: 800px
  margin: auto
  box-sizing: content-box

  +max-w($tablet)
    flex-direction: column

  +max-w($mobile_lg)
    padding: 0

.welcome__part
  position: relative
  flex-shrink: 0
  width: 50%
  padding: 80px 105px
  background-color: #fff

  +max-w($laptop_md)
    padding: 80px 55px

  +max-w($tablet)
    width: 100%

  +max-w($mobile_sm)
    padding: 50px 15px 30px

  &:first-child
    padding: 50px 55px 160px
    background-image: url("../assets/img/welcome/welcome-left-bg.svg")
    background-repeat: no-repeat
    background-size: cover

    +max-w($tablet)
      order: 1

    +max-w($mobile_sm)
      padding: 90px 20px
      background-position: -25px

.welcome__party
  margin-bottom: 25px

  &:last-child
    margin-bottom: 0

.welcome__center
  display: flex
  flex-direction: column
  align-items: flex-start

.welcome__title
  margin-bottom: 20px
  color: #fff
  font-size: 32px
  font-weight: 600

  +max-w($laptop_md)
    font-size: 28px

  +max-w($mobile_sm)
    font-size: 24px

.welcome__instruction
  display: flex
  align-items: center
  margin-bottom: 30px
  font-size: 24px
  font-weight: 600
  color: #fff
  text-decoration: none
  transition: opacity 0.3s

  &:hover
    opacity: 0.7

  +max-w($laptop_md)
    font-size: 20px

.welcome__instruction-text
  margin: 0 20px 0 15px

.welcome__subt
  margin-bottom: 20px
  color: #fff
  font-size: 24px
  font-weight: 600

  +max-w($laptop_md)
    font-size: 20px

.welcome__contacts
  display: flex
  flex-direction: column
  align-items: flex-start

.welcome__contact
  display: flex
  align-items: center
  margin-bottom: 15px
  font-size: 18px
  color: #fff
  text-decoration: none
  line-height: 1
  transition: opacity 0.3s

  &[href]:hover
    opacity: 0.7

  &:last-child
    margin-bottom: 0

  img
    margin-right: 15px

.welcome__logo
  display: block
  margin-bottom: 40px
  text-align: center
  transition: opacity 0.3s

.welcome__bottom
  position: absolute
  bottom: 55px
  left: 55px

  +max-w($mobile_sm)
    position: static
    margin-top: 35px

  a
    display: inline-block

.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0

.welcome__contact-text
  line-height: 1.3
</style>
