<template>
  <div class="wrapper">
    <app-sidebar />
    <main class="main" :class="{ 'main--full': !$store.state.sidebarOpen }">
      <app-header />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>
  </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar'
import AppHeader from '@/components/AppHeader'
// import jwt from '@/api/auth'

export default {
  components: {
    AppSidebar,
    AppHeader,
  },
  // async created() {
  //   jwt.init()
  // },
  mounted() {
    // if (this.$store.state.auth.isUserLoggedIn()) {
    //   this.$store.dispatch('GET_USER')
    //     .then(response => {
    //       if (response.data.permissions.includes('can_admin')) this.$store.dispatch('GET_SYSTEM_INFO')
    //     })
    //     .catch(() => {
    //       this.$notify({
    //         type: 'error',
    //         title: 'Ошибка!',
    //         text: 'Ошибка получения данных'
    //       })
    //     })
    // }
  },
}
</script>

<style lang="sass" scoped>
.wrapper
  flex-grow: 1
  display: flex
  width: 100%
  height: 100%

.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
